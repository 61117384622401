<script>
import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  components: {
  },
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('demand_plan_detail');
  },
  methods: {
    setColumn(v) {
      const rowData = v;
      if (rowData.field === 'companyName') {
        rowData.search = 'true';
        rowData.type = 'select';
        rowData.apiUrl = '/mdm/companyInfo/companyPageList';
        rowData.optionsKey = {
          label: 'companyName',
          value: 'companyName',
        };
        // rowData.multiple = true;
        rowData.isSearch = true;
        rowData.paramsName = 'companyName';
      }
      return rowData;
    },
    modalClick({ val, row }) {
      // if (val.code === 'adjustment') {
      //   this.modalConfig.title = '手动';
      //   this.formConfig.poolId = row.id;
      //   this.formConfig.code = 'edit';
      //   this.openModal();
      // }
      // if (val.code === 'detail') {
      //   this.$router.push(`/capital-pool/cash/detail?poolId=${row.id}`);
      // }
    },
  },
};
</script>
